$browser-context: 16;

@function em($pixels, $context: $browser-context) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px;
	}

	@if (unitless($context)) {
		$context: $context * 1px;
	}

	@return $pixels / $context * 1em;
}

@function rem($pixels, $context: $browser-context) {
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px;
	}

	@if (unitless($context)) {
		$context: $context * 1px;
	}

	@return $pixels / $context * 1rem;
}

// Placeholder text
// ----------------
@mixin placeholder($color: #555555, $opacity: 1) {
	&::-webkit-input-placeholder {
		color: $color;
		opacity: $opacity;
	}
	&::-moz-placeholder {
		color: $color;
		opacity: $opacity;
	}
	&::-ms-input-placeholder {
		color: $color;
		opacity: $opacity;
	}
	&::placeholder {
		color: $color;
		opacity: $opacity;
	}
}